<template>
  <div>
    <div class="tw-pb-4">
      <div data-cy="page-title">
        <h2 class="page-title">Public Holidays Report</h2>
      </div>
    </div>

    <div v-scroll-hint class="table-responsive reports-table">
      <table class="ld-table ld-table-striped" data-cy="table-public-holidays">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Department</th>
            <th>Employee Code</th>
            <th>Public Holiday</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody ref="body">
          <template v-if="employmentHolidays.length">
            <employment-holidays-report-table-row
              v-for="(holiday, index) in employmentHolidays"
              :key="index"
              :holiday="holiday"
            />
          </template>
          <tr v-if="!loading && !employmentHolidays.length">
            <td class="tw-bg-white" colspan="6">
              <NoData />
            </td>
          </tr>
        </tbody>
      </table>
      <loading
        :blur="null"
        loader="dots"
        :is-full-page="false"
        :active.sync="loading"
        :container="$refs.body"
        class="tw-mt-12"
      />
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'

const NoData = () => import('@/components/NoData')
const EmploymentHolidaysReportTableRow = () =>
  import(
    '@/components/reports/employment-holidays/EmploymentHolidaysReportTableRow'
  )

export default {
  name: 'EmploymentHolidaysReportTable',

  components: {
    NoData,
    Loading,
    EmploymentHolidaysReportTableRow,
  },

  props: {
    employmentHolidays: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
