<template>
  <div class="card card-container">
    <div class="tw-py-10">
      <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap">
        <div class="tw-flex tw-items-center">
          <h2 class="page-title">
            Company Calendars
          </h2>
        </div>
        <div class="tw-flex tw-flex-wrap">
          <div>
            <SpinnerButton
              data-cy="btn-add-calendar"
              type="button"
              @click="showCreateCalendarForm"
            >
              Add Calendar
            </SpinnerButton>
          </div>
        </div>
      </div>
    </div>

    <div>
      <p class="tw-mb-2">
        Import leave information into a shared company calendar in Outlook or
        Google.
      </p>
      <p class="tw-mb-2">
        Leave Dates will create one calendar event per day showing who is on
        leave.

        <BaseLink :href="companyCalendarDocumentationUrl">More info</BaseLink>
      </p>
      <div v-scroll-hint class="table-responsive  settings-table">
        <Loading
          loader="dots"
          :is-full-page="false"
          :active="loadingCompanyCalendars"
        />
        <CompanyCalendarTable
          v-if="!loadingCompanyCalendars"
          :company-calendars="companyCalendars"
          @reconnect="connect"
          @edit-calendar="editCalendar"
          @delete-calendar="deleteCalendar"
        />
      </div>
    </div>

    <Modal
      id="modal-select-company-calendar"
      :classes="[
        'tw-shadow-md',
        'tw-bg-white',
        'tw-rounded-lg',
        'modal-overflow-visible',
      ]"
      :max-width="480"
      name="modal-select-company-calendar"
      width="95%"
      height="auto"
      adaptive
      scrollable
      @before-close="resetRoute"
    >
      <div class="modal-header">
        <div class="tw-flex tw-justify-between">
          <div>
            <div class="modal-title">
              Select Company Calendar
            </div>
          </div>
          <div data-cy="edit-provider-close">
            <button class="modal-close" @click="hideSelectCompanyCalendarModal">
              <SvgIcon name="close" class="tw-w-4 tw-h-4" />
            </button>
          </div>
        </div>
      </div>

      <div class="tw-mt-3 tw-p-3">
        <form
          id="select-company-calendar-form"
          class="tw-w-full"
          @submit.prevent="submitCompanyCalendar"
        >
          <div class="form-group">
            <div class="tw-w-full tw-px-3">
              <label class="form-label" for="company-calendars">
                Company Calendar <span class="required-field">&#42;</span>
              </label>
              <p class="tw-mb-6">
                There are multiple calendars associated with this account.
                Select the calendar you would like to use:
              </p>
              <div class="tw-relative">
                <VSelect
                  id="company-calendars"
                  v-model="form.selectedCalendar"
                  v-validate="'required'"
                  :options="availableCalendars"
                  :disabled="loading"
                  :loading="loading"
                  :show-labels="false"
                  :tabindex="1"
                  :allow-empty="false"
                  :max-height="180"
                  placeholder="Select a calendar"
                  name="type"
                  label="name"
                  track-by="id"
                >
                </VSelect>
              </div>
            </div>
          </div>
          <div class="tw-flex tw-flex-wrap tw-mb-3">
            <div class="tw-w-full tw-px-3">
              <div class="tw-flex tw-justify-end">
                <SpinnerButton
                  :disabled="!valid || loading"
                  :spinner-only="true"
                  type="submit"
                >
                  Update
                </SpinnerButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>

    <Modal
      id="modal-company-calendar"
      :classes="[
        'tw-shadow-md',
        'tw-bg-white',
        'tw-rounded-lg',
        'modal-overflow-visible',
      ]"
      :max-width="480"
      name="modal-company-calendar"
      width="95%"
      height="auto"
      adaptive
      scrollable
    >
      <div class="modal-header">
        <div class="tw-flex tw-justify-between">
          <div>
            <div class="modal-title">
              {{ editing ? 'Edit' : 'New' }} Company Calendar
            </div>
          </div>
          <div data-cy="edit-provider-close">
            <button class="modal-close" @click="hideCompanyCalendarForm">
              <SvgIcon name="close" class="tw-w-4 tw-h-4" />
            </button>
          </div>
        </div>
      </div>

      <div class="tw-mt-3 tw-p-3">
        <form
          id="company-calendar-create-form"
          class="tw-w-full"
          @submit.prevent="submit"
        >
          <div class="form-group">
            <div class="tw-w-full tw-px-3">
              <label class="form-label" for="calendar-service">
                Calendar Type<span class="required-field">&#42;</span>
              </label>
              <div class="tw-relative">
                <VSelect
                  id="calendar-service"
                  v-model="form.service"
                  v-validate="'required'"
                  :options="services"
                  :disabled="editing"
                  :show-labels="false"
                  :tabindex="1"
                  :allow-empty="false"
                  :max-height="180"
                  placeholder="Select a calendar type"
                  name="type"
                  label="name"
                  track-by="id"
                >
                </VSelect>
              </div>
            </div>
          </div>

          <div v-if="editing" class="form-group">
            <div class="tw-w-full tw-px-3">
              <label class="form-label" for="company-calendar">
                Calendar <span class="required-field">&#42;</span>
              </label>
              <div class="tw-relative">
                <VSelect
                  id="company-calendar"
                  v-model="form.selectedCalendar"
                  v-validate="'required'"
                  :options="form.calendars"
                  :show-labels="false"
                  :disabled="isEditDisabled"
                  :tabindex="1"
                  :allow-empty="false"
                  :max-height="180"
                  placeholder="Select a calendar"
                  name="type"
                  label="name"
                  track-by="id"
                >
                </VSelect>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="tw-w-full tw-px-3">
              <label class="form-label" for="scopes-picker">
                Show <span class="required-field">&#42;</span>
              </label>
              <VisibilityScopesPicker
                id="scopes-picker"
                v-model="form.scopes"
                :departments="departments"
                :except-their-own-data="true"
              />
            </div>
          </div>

          <div class="form-group">
            <div class="tw-w-full tw-px-3">
              <label class="form-label" for="leave-types-picker">
                Leave Types <span class="required-field">&#42;</span>
              </label>
              <MultipleLeaveTypesPicker
                id="leave-types-picker"
                v-model="form.leave_types"
                :leave-types="leaveTypes"
              />
            </div>
          </div>

          <div class="form-group">
            <div class="tw-w-full tw-px-3">
              <label class="form-label" for="event-title-prefix">
                Event Title <span class="required-field">&#42;</span>
              </label>
              <div class="tw-flex tw-w-full tw-space-x-6">
                <div class="tw-w-1/3">
                  <input
                    id="event-title-prefix"
                    v-model="form.event_title_prefix"
                    v-validate="'required|max:20'"
                    maxlength="20"
                    name="title"
                    class="form-control"
                    type="text"
                    tabindex="3"
                    autocomplete="off"
                  />
                  <p
                    v-show="errors.has('title')"
                    class="tw-mt-1 tw-text-red-700 tw-text-sm"
                  >
                    {{ errors.first('title') }}
                  </p>
                </div>
                <div class="tw-w-2/3 tw-relative">
                  <VSelect
                    id="event-title-format"
                    v-model="form.event_title_format"
                    v-validate="'required'"
                    :options="titleFormats"
                    :show-labels="false"
                    :tabindex="4"
                    :allow-empty="false"
                    :max-height="180"
                    name="type"
                    label="name"
                    track-by="id"
                  >
                  </VSelect>
                </div>
              </div>
            </div>
          </div>

          <div class="tw-flex tw-flex-wrap tw-mb-3">
            <div class="tw-w-full tw-px-3">
              <div class="tw-flex tw-justify-end">
                <SpinnerButton
                  :disabled="
                    !valid ||
                      loading ||
                      !hasSelectedScopes ||
                      !hasSelectedCalendarService ||
                      !hasSelectedLeaveTypes
                  "
                  :loading="loading"
                  :spinner-only="true"
                  type="submit"
                >
                  {{ editing ? 'Update' : 'Connect' }}
                </SpinnerButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  </div>
</template>

<script>
import { find, reduce } from 'lodash-es'
import Loading from 'vue-loading-overlay'
import BaseLink from '@/components/BaseLink'
import { Departments, LeaveTypes } from '@/api'
import ValidatesForm from '@/mixins/ValidatesForm'
import SpinnerButton from '@/components/SpinnerButton'
import documentationUrls from '@/documentations/documentation-urls'
import ExternalCalendars from '@/api/externalcalendars/ExternalCalendars'
import MultipleLeaveTypesPicker from '@/components/MultipleLeaveTypesPicker'
import VisibilityScopesPicker from '@/components/departments/VisibilityScopesPicker'
import CompanyCalendarTable from '@/components/integrations/company-calendar/CompanyCalendarTable'
import FeatureFlags from '@/mixins/FeatureFlags'

const VSelect = () => import('vue-multiselect')

const SERVICES = [
  {
    id: 'google_calendar',
    name: 'Google',
  },
  {
    id: 'outlook_calendar',
    name: 'Outlook',
  },
]

const TITLE_FORMATS = [
  {
    id: 'employee_initials',
    name: 'List of employee initials',
  },
  {
    id: 'employee_first_names',
    name: 'List of employee first names',
  },
  {
    id: 'employee_last_names',
    name: 'List of employee last names',
  },
  {
    id: 'employee_full_names',
    name: 'List of employee full names',
  },
  {
    id: 'employee_codes',
    name: 'List of employee codes',
  },
]

const DEFAULT_LEAVE_TYPE = {
  id: '',
  name: 'All',
}

export default {
  name: 'CompanyCalendars',

  components: {
    Loading,
    VSelect,
    CompanyCalendarTable,
    VisibilityScopesPicker,
    SpinnerButton,
    BaseLink,
    MultipleLeaveTypesPicker,
  },

  mixins: [FeatureFlags, ValidatesForm],

  data() {
    return {
      editing: false,
      loading: false,
      loadingCompanyCalendars: false,
      departments: [],
      services: SERVICES,
      titleFormats: TITLE_FORMATS,
      companyCalendar: null,
      calendarSynchronizations: [],
      availableCalendars: [],
      selectCalendarSynchronizationsFlagIsOn: true,
      form: {
        service: null,
        selectedCalendar: null,
        calendars: null,
        calendar_id: null,
        email: null,
        scopes: null,
        event_title_prefix: null,
        event_title_format: null,
        service_error: null,
        leave_types: [],
      },
      leaveTypes: [DEFAULT_LEAVE_TYPE],
    }
  },

  computed: {
    companyCalendarDocumentationUrl() {
      return documentationUrls.companyCalendarUrl
    },

    hasSelectedScopes() {
      return this.form.scopes && this.form.scopes.length > 0
    },

    hasSelectedCalendarService() {
      return !!this.form.service
    },

    hasSelectedLeaveTypes() {
      return !!this.form.leave_types
    },

    isEditDisabled() {
      return this.isOn('dev-639-disable-company-calendar-edit')
    },

    companyCalendars() {
      return this.calendarSynchronizations.map(synchronization => {
        return {
          ...synchronization,
          serviceName: this.getServiceName(synchronization.service),
          scopeNames: this.getScopesNames(synchronization.scopes).join(', '),
          calendarName: this.getCalendarName(
            synchronization.calendars,
            synchronization.calendar_id
          ),
        }
      })
    },

    scopes() {
      return [...new Set(this.form.scopes)]
    },
  },

  watch: {
    '$route.query.company': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.fetchCalendarSynchronizations()
        this.fetchDepartments()
        this.fetchLeaveTypes()
      },
    },
  },

  mounted() {
    const params = this.$route.params

    if (params.continue) {
      this.showSelectCompanyCalendarForm()
    }
  },

  created() {
    this.fetchLeaveTypes()
  },

  methods: {
    async fetchLeaveTypes() {
      this.loading = true
      const { data } = await LeaveTypes.all(this.$route.query)

      this.leaveTypes = [DEFAULT_LEAVE_TYPE, ...data]
      this.loading = false
    },

    showCreateCalendarForm() {
      this.resetCompanyCalendarModal()

      this.editing = false

      this.$modal.show('modal-company-calendar')
    },

    async showSelectCompanyCalendarForm() {
      this.editing = false

      await this.fetchCompanyCalendars()

      if (this.availableCalendars.length > 1) {
        setTimeout(() => this.$modal.show('modal-select-company-calendar'), 100)
      } else {
        this.resetRoute()
      }
    },

    resetRoute() {
      this.$router.push({ name: 'integrations' }, () => {})
    },

    hideSelectCompanyCalendarModal() {
      this.$modal.hide('modal-select-company-calendar')
      this.resetRoute()
    },

    hideCompanyCalendarForm() {
      this.$modal.hide('modal-company-calendar')
      this.resetCompanyCalendarModal()
    },

    resetCompanyCalendarModal() {
      this.$nextTick(() => {
        this.form = {
          service: null,
          email: null,
          scopes: null,
          event_title_prefix: 'On leave:',
          event_title_format: null,
          service_error: null,
          calendars: null,
          calendar_id: null,
          selectedCalendar: null,
          leave_types: [],
        }

        this.$validator.reset()
        this.errors.clear()
      })
    },

    async submit() {
      await this.validate()

      if (!this.valid) return

      if (this.editing) {
        await this.updateCompanyCalendar()

        return
      }

      await this.connect({
        service: this.form.service.id,
        scopes: this.scopes,
        event_title_prefix: this.form.event_title_prefix,
        event_title_format: this.form.event_title_format.id,
        leave_types: this.form.leave_types,
      })
    },

    async submitCompanyCalendar() {
      if (!this.valid) return

      const calendar = this.calendarSynchronizations.find(
        ({ group }) => group === this.$route.query.id
      )

      try {
        this.loading = true

        await ExternalCalendars.updateCompanyCalendar(
          this.activeCompany.id,
          calendar.group,
          calendar.service,
          calendar.scopes,
          calendar.event_title_prefix,
          calendar.event_title_format,
          this.form.selectedCalendar.id,
          calendar.leave_types
        )

        this.success('Calendar updated successfully.')
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.loading = false
      this.$modal.hide('modal-select-company-calendar')

      await this.fetchCalendarSynchronizations()

      this.resetRoute()
    },

    async updateCompanyCalendar() {
      try {
        this.loading = true

        await ExternalCalendars.updateCompanyCalendar(
          this.activeCompany.id,
          this.form.group,
          this.form.service.id,
          this.scopes,
          this.form.event_title_prefix,
          this.form.event_title_format.id,
          this.form.selectedCalendar.id,
          this.form.leave_types
        )

        this.$modal.hide('modal-company-calendar')

        await this.fetchCalendarSynchronizations()

        this.resetCompanyCalendarModal()

        this.success('Update successfully.')
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.loading = false
    },

    async connect(calendar) {
      try {
        this.loading = true

        const { data } = await ExternalCalendars.connect(
          this.activeCompany,
          calendar
        )

        window.location.href = data.redirect_url
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.loading = false
    },

    editCalendar(calendar) {
      this.editing = true
      this.form = {
        ...calendar,
        selectedCalendar: find(calendar.calendars, cal => {
          return (
            cal.id === calendar.calendar_id ||
            (calendar.calendar_id === null && cal.isDefaultCalendar)
          )
        }),
        service: find(this.services, service => {
          return service.id === calendar.service
        }),
        event_title_format: find(this.titleFormats, format => {
          return format.id === calendar.event_title_format
        }),
      }

      this.$modal.show('modal-company-calendar')
    },

    async deleteCalendar(calendar) {
      const confirm = await this.confirmCalendarDisconnect(calendar)
      if (!confirm) return

      try {
        this.loading = true

        await ExternalCalendars.deleteCalendar(this.activeCompany, calendar)

        await this.fetchCalendarSynchronizations()

        this.success('Delete successfully.')
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.loading = false
    },

    async confirmCalendarDisconnect(calendar) {
      if (calendar.service_error && !calendar.sync_enabled) {
        return await this.confirm(
          'Are you sure you want to delete? There are pending synchronisation jobs for this calendar. ' +
            'If you remove the integration now, we will not be able to sync the latest changes, ' +
            'which could result in problems such as duplicate events.',
          'Confirm delete'
        )
      }

      return await this.confirm(
        'Are you sure you want to delete?',
        'Confirm delete'
      )
    },

    async fetchCalendarSynchronizations() {
      try {
        this.loadingCompanyCalendars = true

        this.calendarSynchronizations = await ExternalCalendars.getSynchronizations(
          this.activeCompany
        )

        this.loadingCompanyCalendars = false
      } catch ({ response }) {
        this.loadingCompanyCalendars = false
        this.validateFromResponse(response, false)
      }
    },

    async fetchCompanyCalendars() {
      try {
        this.loading = true

        this.availableCalendars = await ExternalCalendars.getAvailableCalendars(
          this.$route.query.id
        )

        this.loading = false
      } catch ({ response }) {
        this.loading = false
      }
    },

    async fetchDepartments() {
      try {
        const { data } = await Departments.all(this.$route.query)

        this.departments = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    getServiceName(serviceId) {
      return find(this.services, service => {
        return service.id === serviceId
      }).name
    },

    getScopesNames(scopes) {
      return reduce(
        scopes,
        (names, scope) => {
          if (scope === 'everyone') {
            names.push('Everyone')

            return names
          }

          let departmentInScope = this.departments.find(department => {
            return department.id === scope.split(':')[1]
          })

          if (departmentInScope) {
            names.push(departmentInScope.name)
          }

          return names
        },
        []
      )
    },

    getCalendarName(calendars, calendarId) {
      let calendar = calendars.find(calendar => {
        return calendar.id === calendarId
      })
      if (calendar) {
        return calendar.name
      }
      let defaultCalendar = calendars.find(calendar => {
        return calendar.isDefaultCalendar
      })
      if (defaultCalendar) {
        return defaultCalendar.name
      }
      return 'Primary'
    },
  },
}
</script>
