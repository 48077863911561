<template>
  <tr>
    <td data-cy="holiday-location-holiday-table">
      {{ holidayLocation }}
    </td>
    <td data-cy="assigned-emp-holiday-table">
      {{ assignedEmployments }} of
      {{ totalEmployments }}
      <a
        v-if="!assignedToAll"
        class="tw-ml-2 btn-link tw-font-semibold"
        data-cy="assign-to-all-employees"
        @click.prevent="$emit('assign-to-all-employments')"
      >
        Assign to All
      </a>
    </td>
    <holidays-group
      :holiday-group="holidaysByYear"
      @edit-holidays="editHolidays"
      @delete-holidays="deleteHolidays"
      @add-year="addYear"
    />
  </tr>
</template>

<script>
import HolidaysGroup from './HolidaysGroup'
import { maxBy } from 'lodash-es'

export default {
  name: 'HolidayLocationsTableRow',
  components: { HolidaysGroup },
  props: {
    holidayGroup: {
      type: Object,
      required: true,
    },
  },

  computed: {
    totalEmployments() {
      return this.holidayGroup.totalEmployments
    },

    assignedEmployments() {
      return this.holidayGroup.assignedEmployments
    },

    assignedToAll() {
      return this.assignedEmployments === this.totalEmployments
    },

    holidaysByYear() {
      return this.holidayGroup.holidaysByYear
    },

    holidayLocation() {
      if (this.holidayGroup.state && this.selectedState !== 'All') {
        return `${this.holidayGroup.location} (${this.holidayGroup.state})`
      } else {
        return `${this.holidayGroup.location}`
      }
    },

    upcomingHolidayYear() {
      const maxYear = maxBy(this.holidaysByYear, 'year').year

      return (parseInt(maxYear) + 1).toString()
    },
  },

  methods: {
    addYear() {
      this.$emit('add-year', {
        upcomingHolidayYear: this.upcomingHolidayYear,
      })
    },

    editHolidays(holidayGroup) {
      this.$emit('edit-holidays', {
        ...holidayGroup,
        location: this.holidayGroup.location,
      })
    },

    deleteHolidays(holidayGroup) {
      this.$emit('delete-holidays', {
        ...holidayGroup,
        location: this.holidayGroup.location,
      })
    },
  },
}
</script>
