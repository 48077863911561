<template>
  <div>
    <div v-scroll-hint class="table-responsive scroll-hint">
      <table class="ld-table ld-table-sm ld-td_ontop">
        <thead>
          <tr>
            <th style="width: 35%;">Location</th>
            <th style="width: 20%;">Assigned Employees</th>
            <th style="width: 20%;">Year</th>
            <th style="width: 25%;">Number of Holidays</th>
          </tr>
        </thead>
        <tbody data-cy="holidays-table">
          <tr v-if="loading">
            <td class="tw-bg-white" colspan="4">
              <NoData message="Loading" />
            </td>
          </tr>
          <template v-else>
            <HolidayLocationsTableRow
              v-for="holidayGroup in groupedHolidays"
              :key="holidayGroup.key"
              :holiday-group="holidayGroup"
              @edit-holidays="editHolidays"
              @delete-holidays="deleteHolidays"
              @assign-to-all-employments="
                $emit('assign-to-all-employments', holidayGroup)
              "
              @add-year="addYear(holidayGroup, $event)"
            />
            <tr v-if="!groupedHolidays.length">
              <td class="tw-bg-white" colspan="5">
                <NoData message="No holidays available" />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import HolidayLocationsTableRow from '@/components/holidays/HolidayLocationsTableRow'
import NoData from '@/components/NoData'
import { groupBy, sortBy } from 'lodash-es'
import moment from 'moment-timezone'

export default {
  name: 'HolidayLocationsTable',

  components: { HolidayLocationsTableRow, NoData },

  props: {
    holidays: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    groupedHolidays() {
      let groupedHolidays = groupBy(this.holidays, holiday => {
        return holiday.location + ' (' + holiday.state + ')'
      })

      groupedHolidays = Object.entries(groupedHolidays).map(entry => {
        const holidayByYear = groupBy(entry[1], holiday => {
          return moment(holiday.date).year()
        })

        return {
          key: entry[0],
          location: entry[1][0].location,
          totalEmployments: entry[1][0].total_employments,
          assignedEmployments: entry[1][0].assigned_employments,
          state: entry[1][0].state,
          holidaysByYear: Object.entries(holidayByYear).map(holiday => {
            return {
              year: holiday[0],
              state: holiday[1][0].state,
              holidays: holiday[1],
            }
          }),
        }
      })

      return sortBy(groupedHolidays, 'key')
    },
  },

  methods: {
    editHolidays(holidayLocation) {
      this.$emit('edit-holidays', holidayLocation)
    },

    deleteHolidays(holidayLocation) {
      this.$emit('delete-holidays', holidayLocation)
    },

    addYear(holidayGroup, { upcomingHolidayYear }) {
      this.$emit('add-year', {
        ...holidayGroup,
        year: upcomingHolidayYear,
      })
    },
  },
}
</script>
