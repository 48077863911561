<template>
  <div
    ref="target"
    data-cy="avatar"
    class="tw-flex-shrink-0 tw-overflow-hidden tw-rounded-full"
    :class="sizeClasses"
  >
    <img
      v-if="user.avatar_url"
      :src="user.avatar_url"
      :alt="user.full_name"
      class="tw-block"
    />

    <div
      v-else
      :style="`background:${userColor.backgroundColor}`"
      data-cy="avatar-background"
      class="tw-flex tw-h-full tw-items-center tw-justify-center tw-self-center"
    >
      <span
        :style="`color:${userColor.color}`"
        class="avatar-title tw-font-semibold tw-pointer-events-none"
      >
        {{ username }}
      </span>
    </div>

    <div v-show="false" ref="content">
      <div class="tw-p-3" data-cy="user-full-name">
        {{ user.full_name }}
      </div>
    </div>
  </div>
</template>

<script>
import colorHash from 'material-color-hash'
import tippy from 'tippy.js'

const SIZES = {
  default: ['user-avatar-wrap'],

  sm: ['user-avatar-wrap-sm'],
}

export default {
  name: 'UserAvatar',

  props: {
    user: {
      type: Object,
      required: true,
    },

    showTooltip: {
      type: Boolean,
      default: false,
    },

    tooltipPlacement: {
      type: String,
      default: 'bottom',
    },

    size: {
      type: String,
      default: 'default',
    },
  },

  computed: {
    username() {
      return (
        this.user.first_name.charAt(0).toUpperCase() +
        this.user.last_name.charAt(0).toUpperCase()
      )
    },

    userColor() {
      return colorHash(this.user.full_name)
    },

    sizeClasses() {
      return SIZES[this.size]
    },
  },

  mounted() {
    if (this.showTooltip) {
      this.tippy = tippy.one(this.$refs.target, {
        html: this.$refs.content.firstChild,
        theme: 'dark',
        arrow: true,
        interactive: true,
        placement: this.tooltipPlacement,
        delay: [400, 0],
        distance: 1,
        interactiveBorder: 15,
        onShow: this.hideOtherTooltips,
      })

      this.$once('hook:beforeDestroy', () => {
        this.tippy.destroy()
      })
    }
  },

  methods: {
    hideOtherTooltips() {
      ;[...document.querySelectorAll('.tippy-popper')].forEach(popper => {
        popper._tippy.hide()
      })
    },
  },
}
</script>
