<template>
  <div>
    <div class="tw-pb-4">
      <div data-cy="page-title"><h2 class="page-title">Leave Report</h2></div>
    </div>

    <div v-scroll-hint class="table-responsive reports-table">
      <table class="ld-table ld-table-striped" data-cy="table-leave-report">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Department</th>
            <th>Employee Code</th>
            <th>Type</th>
            <th>Amount</th>
            <th>From</th>
            <th>To</th>
            <th>Requested</th>
            <th>Status</th>
            <th>Last updated</th>
            <th>Updated by</th>
            <th>Private</th>
            <th>Reason</th>
          </tr>
        </thead>
        <tbody ref="body">
          <template v-if="leaveReports.length">
            <LeaveDetailReportTableRow
              v-for="leaveReport in leaveReports"
              :key="leaveReport.id"
              :leave-report="leaveReport"
            />
          </template>
          <tr v-if="!loading && !leaveReports.length">
            <td class="tw-bg-white" colspan="14">
              <NoData />
            </td>
          </tr>
        </tbody>
      </table>
      <Loading
        :blur="null"
        loader="dots"
        :is-full-page="false"
        :active.sync="loading"
        :container="$refs.body"
        class="tw-mt-12"
      />
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'

const NoData = () => import('@/components/NoData')
const LeaveDetailReportTableRow = () =>
  import('@/components/reports/leave/LeaveDetailReportTableRow')

export default {
  name: 'LeaveDetailReportTable',

  components: {
    NoData,
    Loading,
    LeaveDetailReportTableRow,
  },

  props: {
    leaveReports: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
