<template>
  <div>
    <div class="tw-mt-3 tw-p-3">
      <form class="tw-w-full" @submit.prevent="submit">
        <div class="form-group">
          <div class="tw-w-full tw-px-3">
            <label class="form-label" for="year">
              Year <span class="required-field">&#42;</span>
            </label>
            <input
              id="year"
              v-model="form.year"
              v-validate="'required|date_format:yyyy'"
              v-focus
              name="year"
              data-vv-validate-on="input"
              data-vv-as="year"
              data-cy="holidays-year"
              class="form-control"
              type="text"
              autocomplete="off"
              placeholder="YYYY"
              inputmode="decimal"
              @input="getHolidays"
            />

            <p
              v-show="errors.has('year')"
              class="tw-mt-1 tw-text-red-700 tw-text-sm"
            >
              {{ errors.first('year') }}
            </p>
          </div>
        </div>

        <div class="form-group">
          <div class="tw-w-full tw-px-3">
            <label class="form-label" for="country">
              Country <span class="required-field">&#42;</span>
            </label>
            <VSelect
              id="country"
              v-model="form.location"
              v-validate="'required'"
              :options="sortedCountries"
              :show-labels="false"
              :max-height="130"
              :allow-empty="false"
              placeholder="Select a country to find holidays"
              name="country"
              data-vv-as="country"
              data-cy="holidays-country"
              label="name"
              track-by="name"
              @input="getHolidays"
            >
              <template #singleLabel="props">
                <div
                  class="tw-flex tw-justify-start tw-items-center tw-content-center"
                >
                  <flag
                    :squared="false"
                    :iso="props.option.iso_code"
                    class="tw-mr-2"
                  />
                  <div
                    class="tw-text-base tw-font-normal"
                    data-cy="selected-holidays-country"
                  >
                    {{ props.option.name }}
                  </div>
                </div>
              </template>
              <template #noResult>
                <span>No items found.</span>
              </template>
              <template #option="props">
                <div
                  class="tw-flex tw-justify-start tw-items-center tw-content-center tw-leading-zero"
                >
                  <flag
                    :squared="false"
                    :iso="props.option.iso_code"
                    class="tw-mr-2"
                  />
                  <div
                    class="tw-text-base tw-font-normal tw-leading-none"
                    data-cy="selected-holidays-country"
                  >
                    {{ props.option.name }}
                  </div>
                </div>
              </template>
            </VSelect>
            <p
              v-show="errors.has('country')"
              class="tw-mt-1 tw-text-red-700 tw-text-sm"
            >
              {{ errors.first('country') }}
            </p>
          </div>
        </div>

        <div class="form-group">
          <div class="tw-w-full tw-px-3" data-cy="drpdown-region">
            <label class="form-label" for="state">
              Region <span class="required-field">&#42;</span>
            </label>
            <VSelect
              id="state"
              v-model="form.state"
              v-validate.disable="'required'"
              :options="states"
              :show-labels="false"
              :max-height="130"
              :loading="loading"
              :allow-empty="false"
              :disabled="loading"
              placeholder="Select region"
              name="state"
              data-vv-as="state"
              data-cy="drpdown-region-name"
            >
              <template #noResult>
                <span>No items found.</span>
              </template>
            </VSelect>

            <p
              v-show="errors.has('state')"
              class="tw-mt-1 tw-text-red-700 tw-text-sm"
            >
              {{ errors.first('state') }}
            </p>
          </div>
        </div>

        <div class="tw-flex tw-flex-wrap tw-mb-3">
          <div class="tw-w-full tw-px-3">
            <div class="tw-flex tw-justify-end">
              <button
                class="btn btn-blue tw-rounded-lg"
                data-cy="next-holiday-form"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { sortBy, debounce } from 'lodash-es'
import ValidateForm from '@/mixins/ValidatesForm'
import VSelect from 'vue-multiselect'

export default {
  name: 'LocationYearForm',

  components: { VSelect },

  mixins: [ValidateForm],

  props: {
    countries: {
      type: Array,
      required: true,
    },

    states: {
      type: Array,
      required: true,
    },

    selectedLocation: {
      type: String,
      default: () => '',
    },

    selectedState: {
      type: String,
      default: () => '',
    },

    selectedYear: {
      type: String,
      default: () => '',
    },

    loading: {
      type: Boolean,
      required: true,
    },

    customStates: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: {
        location: null,
        year: this.selectedYear,
        state: this.selectedState,
      },
    }
  },

  computed: {
    sortedCountries() {
      return sortBy(this.countries, 'name')
    },
  },

  watch: {
    states(val) {
      if (
        !this.selectedState &&
        (val[0] === this.customStates.all ||
          val[0] === this.customStates.nationalHolidaysOnly)
      ) {
        this.form.state = val[0]
      }
    },
  },

  mounted() {
    this.fetchHolidayStates()

    if (this.selectedLocation) {
      this.form.location = this.countries.find(country => {
        return country.name === this.selectedLocation
      })
    }

    if (!this.selectedState) {
      this.fetchHolidayStates()
    }
  },

  methods: {
    getHolidays() {
      this.fetchHolidayStates()
      this.form.state = ''
    },

    fetchHolidayStates: debounce(function() {
      if (!this.form.location || !this.fields.year.valid) return

      this.$emit('location-year-changed', this.form)
    }, 200),

    async submit() {
      await this.validate()

      if (!this.valid) {
        return
      }

      if (!this.form.location.name) {
        this.errors.add({
          field: 'location',
          msg: 'Country field is required.',
        })

        return
      }

      this.$emit('submit', this.form)
    },
  },
}
</script>
