import http from '@/plugins/http'

export default class Holidays {
  static async save(payload) {
    const { data } = await http.post('company-holidays', payload)

    return data
  }

  static async remove(company, holiday) {
    const { data } = await http.delete(`company-holidays/${holiday.id}`, {
      data: {
        company_id: company.id,
      },
    })

    return data
  }
}
