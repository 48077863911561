<template>
  <div v-scroll-hint class="table-responsive">
    <table class="ld-table ld-table-striped" data-cy="table-toil-report">
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Department</th>
          <th>Employee Code</th>
          <th>Total Count</th>
          <th>Total Amount</th>
        </tr>
      </thead>
      <tbody ref="body">
        <template v-if="summarisedOvertimes.length">
          <OvertimeSummaryReportTableRow
            v-for="(summarisedOvertime, index) in summarisedOvertimes"
            :key="index"
            :summarised-overtime="summarisedOvertime"
          />
        </template>
        <tr v-if="!loading && !summarisedOvertimes.length">
          <td class="tw-bg-white" colspan="11">
            <NoData />
          </td>
        </tr>
      </tbody>
    </table>
    <loading
      :blur="null"
      loader="dots"
      :is-full-page="false"
      :active.sync="loading"
      :container="$refs.body"
      class="tw-mt-12"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'

const NoData = () => import('@/components/NoData')
const OvertimeSummaryReportTableRow = () =>
  import('@/components/reports/overtime/OvertimeSummaryReportTableRow')

export default {
  name: 'OvertimeSummaryReportTable',

  components: {
    NoData,
    Loading,
    OvertimeSummaryReportTableRow,
  },

  props: {
    summarisedOvertimes: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
